import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../layout';
import Listing from '../components/PostListing/PostListing';
import config from '../../data/SiteConfig';

class AboutPage extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`About | ${config.siteTitle}`} />
          <Listing />
        </div>
      </Layout>
    );
  }
}

export default AboutPage;
